export default {
  id: {
    type: Number,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  branch: {
    label: 'Modulo',
    type: String,
    options: [{
      id: 'devices',
      name: 'Dispositivos GPS'
    }, {
      id: 'cameras',
      name: 'Camaras de Video Vigilancia (PMI)'
    }, {
      id: 'lpr',
      name: 'Lectores de Placa (LPR)'
    }, {
      id: 'incidents',
      name: 'Incidencia 911 (cad)'
    }, {
      id: 'sensor',
      name: 'Botones de panico'
    }],
    onChange: (value, fields) => {
      const isDevices = value === 'devices'
      fields.instantMessenger.setVisibility(isDevices);
      fields.phoneList.setVisibility(isDevices);
    }
  },
  groupId: {
    label: 'Grupo',
    type: Number,
    optional: true,
    model: 'groups',
    autocomplete: false
  },
  instantMessenger: {
    label: 'Mensajería instantanea',
    type: String,
    optional: true,
    visibility: false,
    options: [{
      id: 'None',
      name: 'Niguna'
    }, {
      id: 'Whatsapp',
      name: 'Whatsapp'
    }, {
      id: 'Telegram',
      name: 'Telegram'
    }]
  },
  phoneList: {
    label: 'Listado de teléfonos',
    type: Array,
    optional: true,
    visibility: false
  },
  'phoneList.$': {
    type: String,
    options: true
  },
  attributes: {
    label: 'Atributos',
    type: Object,
    optional: true,
    attributes: true
  }
};
