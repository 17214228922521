<template>
  <v-card
    class="mx-auto elevation-0"
    height="100%"
  >
    <v-toolbar
      class="kalan-titles elevation-0"
      color="primary--text"
    >
      <v-toolbar-title>
        <span>{{ $vuetify.lang.t(`$vuetify.${model}.plural_name`) }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="margin-right: 20px;">
        <v-text-field
          flat
          label="Buscar"
          hide-details
          v-model="search"
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <actions-button :item="{}" :actions="actions"></actions-button>
    </v-toolbar>
    <kalan-list
      :loading="loading"
      :getTitle="getTitle"
      :getSubTitle="getSubTitle"
      :getExtra="getExtra"
      :items="items"
      :search="search"
      :actions="listActions"
    >
    </kalan-list>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '@/util/api.service'
import Groups from '@/models/groups.model'
import Crud from '@/util/crud.service'

export default {
  props: {
    model: {
      default: 'groups'
    },
  },
  computed: {
    ...mapGetters('auth', ['getUser'])
  },
  components: {
    'kalan-list': () => import('@/components/modulo/List.vue'),
    'actions-button': () => import('@/components/modulo/Actions.vue')
  },
  data() {
    return {
      items: [],
      attributeFields: [
        'instantMessenger',
        'phoneList',
      ],
      branches: {
        devices: 'Dispositivos GPS',
        cameras: 'Camaras de Video Vigilancia(PMI)',
        lpr: 'Lectores de Placa (LPR)',
        incidents: 'Incidentes 911 (CAD)',
        sensor: 'Botones de Panico',
      },
      loading: true,
      search: '',
      actions: [{
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.create`),
        icon: 'mdi-plus',
        color: 'secondary',
        action: (item) => {
          Crud.create({
            item, schema: Groups, model: this.model, preSave: this.preSave
          }).then(this.load)
        }
      }],
      listActions: [{
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.update`),
        icon: 'mdi-pencil',
        color: 'accent',
        action: (item) => {
          Crud.update({
            item,
            schema: Groups,
            model: this.model,
            title: this.getTitle(item),
            preSave: this.preSave
          }).then(this.load)
        }
      }, {
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.read`),
        icon: 'mdi-eye',
        color: 'mattBlack',
        action: (item) => {
          Crud.read({
            item,
            schema: Groups,
            model: this.model,
            title: this.getTitle(item)
          })
        }
      }, {
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.delete`),
        icon: 'mdi-delete',
        color: 'red',
        action: (item) => {
          Crud.delete({
            id: item.id,
            model: this.model,
            title: this.getTitle(item)
          }).then(this.load)
        }
      }],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    getTitle: (item) => item.name,
    getSubTitle(item) {
      return this.branches[item.branch]
    },
    getExtra: () => null,
    preSave(item) {
      const it = Object.assign(item, {})
      this.attributeFields.forEach((field) => {
        it.attributes[field] = it[field]
        delete it[field]
      })
      return it
    },
    load() {
      this.loading = true
      const url = `/${this.model}`
      ApiService({
        url,
        method: 'get',
      }).then((resp) => {
        this.items = resp.map((it) => {
          this.attributeFields.forEach((field) => {
            it[field] = it.attributes[field]
            delete it.attributes[field]
          })
          return it
        })
        this.loading = false
      }).catch((err) => {
        this.loading = false
      })
    },
  }
}
</script>
